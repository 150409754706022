var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


var totalVids = document.querySelectorAll('.youtube').length;

function onYouTubeIframeAPIReady() {
  for (var i = 1; i < totalVids + 1; i++) {

    var videoid = document.getElementById('player-' + i).dataset.videoid;
    console.log(videoid);
    new YT.Player('player-' + i , {
      height: '225',
      width: '400',
      videoId: videoid,
    });
  }
}
