/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.card-content-excerpt').dotdotdot();

        $('.fancy-link').mouseenter(function(){
          $(this).find('.fancy-arrow')
          .animate({ left: "+=30" }, {duration: 100})
          .animate({ opacity: 0}, {duration:0})
          .animate({ left: "-=60" }, {duration: 100})
          .animate({ opacity: 1}, {duration:0})
          .animate({ left: "+=30" }, {duration: 100})
        });

        $('.content-video-thumbnail').click(function(e){
          e.preventDefault();
          $(this).remove();
          var player = YT.get($(this).data('player'));
          player.playVideo();
        });

        if (!$('.nextpostslink').length) {
          $('.wp-pagenavi').append('<span class="nextpostslink">></span>');
        }
        if (!$('.previouspostslink').length) {
          $('.wp-pagenavi').append('<span class="previouspostslink"><</span>');
        }

        $('.carousel').slick({
          arrows: false,
          dots: true,
          adaptiveHeight: true
        });

        // var wH = $(window).height();
        var wW = $(window).width();
        //
        // if (wW <= 767) {
        //   $('nav.nav-primary').height(wH);
        // }

        $('.navbar-toggle').on('click', function() {
          $('.eecmd-page').toggleClass('menu-opened');
          $('nav.nav-primary').toggleClass('menu-opened');
          $('html').toggleClass('freeze');
          // $('.wrap').append('<div class="xxx"></div>');
        });

        $('.menu-item-has-children').click(function(){
          $(this).find('ul').toggleClass('mobile-children-active');
          $(this).toggleClass('parent-active');
        });

        var additionalBreadcrumb = $(".about-page-container").data('dummybreadcrumb');

        if (additionalBreadcrumb) {
          $(".nav-breadcrumb .breadcrumb-separator").after(additionalBreadcrumb + '<div class="breadcrumb-separator"></div>')
        }

        function twitterDialogue() {
          alert('working');
        }

        var langMobile = false;
        $(".lang-nav").click(function(){
          if (langMobile == false) {
            $(".mobile-language-dropdown").css('max-height', '300px');
            $(".current-language").addClass('opened');
            langMobile = true;
          } else {
            $(".mobile-language-dropdown").css('max-height', '0');
            $(".current-language").removeClass('opened');
            langMobile = false;
          }
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        video = $('#home-video').get(0);
        var wW = $(window).width();

        $('.video-control-mute').click(function(e){
          if (video.muted == false) {
              video.muted = true;
              $('.mute-toggle').removeClass('unmute');
            } else {
              video.muted = false;
              $('.mute-toggle').addClass('unmute');
            }
        });

        $("#playpause").click(function(e){
          if (video.paused == true) {
            video.play();
            $('#playpause #play').hide();
            $('#playpause #pause').show();
          } else {
            video.pause();
            $('#playpause #play').show();
            $('#playpause #pause').hide();
          };
        });
        $("#playpausemobile").click(function(e){
          if (video.paused == true) {
            video.play();
            $('#playpausemobile #play').hide();
            $('#playpausemobile #pause').show();
          } else {
            video.pause();
            $('#playpausemobile #play').show();
            $('#playpausemobile #pause').hide();
          };
        });

        // console.log(video);
        if (video) {
          if (wW > 767) {
            video.play();
          }
          video.addEventListener('timeupdate', updateProgressBar, false);
        }

        function updateProgressBar() {
           var progressBar = document.getElementById('progress-bar');
           var percentage = Math.floor((100 / video.duration) *
           video.currentTime);
           progressBar.value = percentage;
        }

       $('.play-mobile-video').click(function(){
         $('.video_poster').remove();
         video.play();
         video.muted = false;
       });

       var mobileControl = false;
       $(".mobile-control").click(function() {
         if (!mobileControl) {
           $(this).css('opacity', 1);
           mobileControl = true;
           fadeControlOut();
         }
       });

       function fadeControlOut() {
         setTimeout(function(){
           $(".mobile-control").css('opacity', 0);
           mobileControl = false;
         }, 3000);
       }


        $(".mobile-caption-carousel").slick({
          arrows: false,
          swipe: false,
          touchMove: false
        });

        $(".header-carousel").slick({
          dots: true,
          asNavFor: ".mobile-caption-carousel"
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'post_type_archive_cpt_teams': {
      init: function() {
        $(".team-read-more").click(function(e){
          $(this).css('opacity', 0);
          $(this).css('visibility', 'hidden');
        });

        $(".team-read-less").click(function(e){
          var teamid = $(this).attr('aria-controls');
          $('.team-read-more-'+teamid).css('opacity', 1);
          $('.team-read-more-'+teamid).css('visibility', 'visible');
        });

        $('.team-read-less-mobile').hide();

        $(".team-read-more-mobile").click(function(e){
          var teamidmobile = $(this).attr('aria-controls');
          $(this).hide();
          $('.team-read-less-' + teamidmobile).show();
        });
        $(".team-read-less-mobile").click(function(e){
          var teamidmobile = $(this).attr('aria-controls');
          $(this).hide();
          $('.team-read-more-' + teamidmobile).show();
        });

      }
    },
    'single_post': {
      init: function() {
        $(".news-archive").addClass('current-menu-item');
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
